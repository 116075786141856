import { message } from "antd";
import axios, { AxiosInstance } from "axios";
import endpoints from "../config.production";
export const BASE_URL = endpoints.baseUrl;
export const COURSES_URL = endpoints.courseUrl;
export const BROADCAST_URL = endpoints.broadcastUrl;
export const RTMP_SERVER = endpoints.rtmpURl;
export const ADMIN_BASE_URL = endpoints.adminBaseUrl;
export const LOGIN_URL = endpoints.loginUrl;
export const PLAYER_URL = endpoints.playerUrl;
export const PLAYER_URL_NEW = endpoints.playerUrlNew

export const getChatUrl = (room: string, token: string) =>
  `https://chat.kgs.live/?room=${room}&token=${token}&medium=studio`;

interface AxiosIns extends AxiosInstance {
  admin: () => AxiosIns;
}
const api = axios.create({
  baseURL: BASE_URL + "dashboard",
  timeout: 10000,
  headers: {},
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    autoErrorHandle(err);
    throw err;
  }
);
//@ts-ignore
api.admin = function (token: string) {
  api.defaults.baseURL = ADMIN_BASE_URL;
  return api;
};

export const loginRequest = async () => {
  const token = localStorage.getItem("AUTH_TOKEN");
  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

const autoErrorHandle = (error: any) => {
  if (!error) return;
  if (!error.response) return;
  if (error.response && error.response.status === 401) {
    delete api.defaults.headers.Authorization;
    window.location.pathname = "/login";
  }

  if (!error.response.data) return;

  if (error?.response?.data?.data) {
    const errorData = error.response.data?.data;
    Object.keys(errorData).forEach((e) => {
      message.error(errorData[e]);
    });
  }
  if (error.response.data.message) {
    message.error(error.response.data.message);
  }
  return;   
};

export default api as AxiosIns;
