import { Button, Divider, Input, Row, Spin, Col } from "antd";
import { useEffect, useState } from "react";
import HlsVideoTrimmer from "../component/editor/videoTrimmer";
import api, { BASE_URL } from "../api";
import { useSearch } from "@tanstack/react-router";
import { Select } from "antd";

export type TTrimType = "keep" | "trim";

const { Option } = Select;

export default function Editor() {
  const query = useSearch({ from: "/editor" });
  const [hlsLiveStreamUrl, setHlsLiveStreamUrl] = useState("");
  const [rangeValue, setRangeValue] = useState<[number, number]>([0, 0]);
  const [trimType, setTrimType] = useState<TTrimType>("keep");
  const [trimmedUrl, setTrimmedUrl] = useState([]);
  const [secret, setSecret] = useState(0);
  const [steps, setSteps] = useState({
    loadingTrim: false,
    loadingUpload: false,
    loadingDownload: false,
    doneDownload: false,
    doneTrim: false,
    doneUpload: false,
  });

  useEffect(() => {
    if (!query.url) return;
    setHlsLiveStreamUrl(query.url);
  }, [query?.url]);

  const handleTrim = async () => {
    try {
      setSteps((steps) => ({
        ...steps,
        loadingTrim: true,
      }));
      const { data, status } = await api.post("trim", {
        type: trimType,
        videoUrl: hlsLiveStreamUrl,
        start: rangeValue[0],
        end: rangeValue[1],
      });
      if (status === 200) {
        setTrimmedUrl(data.destination);
        setSecret(data.secret);
        setSteps((steps) => ({
          ...steps,
          doneTrim: true,
        }));
      }
    } catch (e: any) {
      console.log({ e });
    }
    setSteps((steps) => ({
      ...steps,
      loadingTrim: false,
    }));
  };
  const handleUpload = async () => {
    console.log({ secret });
    setSteps((steps) => ({
      ...steps,
      loadingUpload: true,
    }));
    try {
      const { data, status } = await api.post("trim/upload", {
        videoId: query.vid,
        urls: trimmedUrl,
        secret: secret,
      });
      console.log({ data });
      setHlsLiveStreamUrl(data.data);
      setSteps((steps) => ({
        ...steps,
        doneUpload: true,
      }));
    } catch (e) {
      console.log({ e });
    }
    setSteps((steps) => ({
      ...steps,
      loadingUpload: false,
    }));
  };
  const handleDownload = async () => {
    const response = await fetch(BASE_URL + "dashboard/trim/download", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        videoId: query.vid,
        urls: trimmedUrl,
        secret,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to download zip file");
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    const match = hlsLiveStreamUrl.match(/\/([^/]+)\.[^/.]+$/) || [""];
    a.download = match[1] + ".zip";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setSteps((steps) => ({
      ...steps,
      doneDownload: true,
    }));
  };
  const handleChange = (value: any) => {
    setTrimType(value);
  };
  function StatusCard({
    title,
    onClick,
    backround,
    loading,
    disable = false,
  }: any) {
    return (
      <div style={{ width: "48%", margin: 5 }}>
        <Button
          onClick={onClick}
          disabled={disable}
          style={{
            width: "100%",
            height: 100,
            textAlign: "center",
            backgroundColor: backround,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {title}
          {loading && <Spin style={{ marginLeft: 10 }} />}
        </Button>
      </div>
    );
  }
  return (
    <Row>
      <Input
        value={hlsLiveStreamUrl}
        onChange={(e) => setHlsLiveStreamUrl(e.target.value)}
      />
        <>
          <HlsVideoTrimmer
            loading={steps.loadingTrim||steps.loadingUpload}
            trimType={trimType}
            rangeValue={rangeValue}
            setRangeValue={setRangeValue}
            hlsLiveStreamUrl={hlsLiveStreamUrl}
          />
         
          <Row style={{ width: "100%", marginTop: 10 }}>
            <Col style={{ margin: 20 }}>
              <Select value={trimType} onChange={handleChange}>
                <Option value="keep">Keep Selected</Option>
                <Option value="trim">Trim Selected</Option>
              </Select>
            </Col>
          </Row>
          <div
            style={{
              border: "1px solid #DDD",
              padding: 8,
              display: "flex",
              marginLeft: 20,
              width: "50%",
            }}
          >
            <StatusCard
              title="Trim Video"
              onClick={handleTrim}
              backround={steps.doneTrim ? "#C7F6C7" : "white"}
              loading={steps.loadingTrim}
            />
            {!query.vid?<StatusCard
              disable={!steps.doneTrim||query.vid}
              title="Download Video"
              onClick={handleDownload}
              backround={steps.doneDownload ? "#C7F6C7" : "white"}
              loading={steps.loadingDownload}
            />:
            <StatusCard
              disable={!steps.doneTrim||!query.vid}
              title="Upload Video"
              onClick={handleUpload}
              backround={steps.doneUpload ? "#C7F6C7" : "white"}
              loading={steps.loadingUpload}
            />}
          </div>
        </>
      <Divider />
    </Row>
  );
}
