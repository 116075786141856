import { message } from "antd";
import { useState, useEffect } from "react";

export const copyToClip = (text: string) => navigator.clipboard.writeText(text);

export default function useClipboard() {
  const copyToClip = (text: string, msg = "Text Copied") => {
    navigator.clipboard.writeText(text);
    message.success(msg);
  };

  return { copy: copyToClip };
}


export const useOnline = () =>{
  const [isOnline, setIsOnline] = useState(true)
  useEffect(()=>{
    const handleOnline = ()=>{
      setIsOnline(true)
    }
    const handleOffline = ()=>{
      setIsOnline(false)
    }
    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)
    return () =>{
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
  }
  },[])
  return isOnline;
}

export const formatDuration = (duration: number) =>
  `${Intl.NumberFormat([], {
    minimumIntegerDigits: 2,
  }).format(Math.floor(duration / 60))}:${Intl.NumberFormat([], {
    minimumIntegerDigits: 2,
  }).format(Math.round(duration % 60))}`;
