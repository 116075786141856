import axios from "axios";
import { useEffect, useState } from "react";
import api, { ADMIN_BASE_URL } from "../api";
import {
  Space,
  Card,
  Table,
  Button,
  Badge,
  Image,
  Select,
  DatePicker,
  Checkbox,
  Input,
  Dropdown,
  MenuProps,
  Modal,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { VideoCameraFilled } from "@ant-design/icons";
import moment from "moment";
import { Link, useNavigate } from "@tanstack/react-router";
import { ISchedule, IVideo } from "../interfaces";
import { message } from "antd";
import useCourseStore from "../store/course";
import { ICourse } from "./list";

const INITIAL_FILTER = { course: 0, date: "", videoId: "" };
const UN_AUTHENTICATED_CODE = 401;

export default function Schedules() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [resData, setResData] = useState<ISchedule[]>([]);
  const [filteredCourses, setFilteredCourses] = useState<ICourse[]>([]);
  const { courses } = useCourseStore();
  const [schedules, setSchedules] = useState<ISchedule[]>([]);
  const [multiples, setMultiples] = useState<IVideo[]>([]);
  const [filter, setFilter] = useState(INITIAL_FILTER);
  const [youtubeUrl, setYoutubeUrl] = useState("");

  const navigate = useNavigate({ from: "/schedules" });
  const getSchedules = async () => {
    try {
      const Authorization = "Bearer " + localStorage.getItem("AUTH_TOKEN")
      const { data } = await axios.get(ADMIN_BASE_URL + `schedules`, {headers: { Authorization }});
      setLoading(false);
      setResData(data);
      setSchedules(data);
    } catch (e: any) {
      if (e?.response?.status === UN_AUTHENTICATED_CODE) {
        localStorage.removeItem("AUTH_TOKEN");
        navigate({ to: "/login" });
      }
    }
  };

  function filterSchedule(filter: any) {
    if (filter.videoId) return resData.filter((s) => String(s.id).match(filter.videoId));
    if (filter.date === "" && filter.course ) return resData;

    return resData.filter((data: any) => {
      const matched = data.course.id === filter.course
      if (filter.course && filter.date)
        return ( matched && moment(data?.published_at).isSame(filter.date, "day"));
      return (matched || moment(data?.published_at).isSame(filter.date, "day")
      );
    });
  }

  useEffect(() => {
    setSchedules(filterSchedule(filter));
  }, [filter]);

  useEffect(() => {
    setFilteredCourses(courses);
  }, [courses]);

  useEffect(() => {
    getSchedules();
  }, []);

  const handleScheduleLive = async (record: any) => {
    const bucketId = localStorage.getItem("default_bucket");
    if (!bucketId) return message.error("Please select bucket ");
    const payload = {
      vid: record.id,
      title: record.name,
      course_name: record.course.title,
      course_id: record.course.id,
      lesson_id: record.name,
      bucket: bucketId,
    };
    try {
      const { data } = await api.post<IVideo>("video", payload);
      navigate({ to: `/studio/${data._id}` });
    } catch (e: any) {
      console.log({ e });
    }
  };

  const columns: ColumnsType<ISchedule> = [
    {
      render: (_, record: any) => (
        <Checkbox
          checked={!!multiples.find((mul) => mul.vid === record.id)}
          onChange={(arg) => {
            const bucketId = localStorage.getItem("default_bucket");
            const selectedVideo: any = {
              key: record.key,
              vid: record.id,
              title: record.name,
              course_name: record.course.title,
              course_id: record.course.id,
              lesson_id: record.name,
              bucket: bucketId,
            };
            const checked = arg.target.checked;
            if (checked === true) {
              return setMultiples((multiples) => [...multiples, selectedVideo]);
            }
            return setMultiples((multiples) =>
              multiples.filter((item: any) => item.vid !== selectedVideo.vid)
            );
          }}
        ></Checkbox>
      ),
    },
    {
      title: "S No",
      dataIndex: "index",
      key: "index",
      render: (a, b, index) => {
        return <p>{index + 1}</p>;
      },
      responsive: ["lg", "md"],
    },
    {
      title: "VID",
      dataIndex: "id",
      key: "id",
      align: "center",
      responsive: ["lg", "md"],
    },
    {
      title: <div style={{ textAlign: "center" }}>Topic</div>,
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Space size="middle">
          <Image
            src={record.thumb || "./default_image.jpg"}
            height="40px"
            width="auto"
          />
          <p style={{ textAlign: "center" }}>{record.name}</p>
        </Space>
      ),
    },
    {
      title: "Course",
      dataIndex: "course",
      key: "course",
      render: (course) => <a>{course.title}</a>,
      align: "center",
      responsive: ["lg"],
    },
    {
      title: "Publish At",
      dataIndex: "published_at",
      key: "published_at",
      render: (published_at) => (
        <Badge>{moment(published_at).format("DD-MM-YY HH:SS")}</Badge>
      ),
      align: "center",
      responsive: ["lg", "md"],
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleScheduleLive(record)}>
            <VideoCameraFilled color="red" />
          </Button>
        </Space>
      ),
      align: "center",
    },
  ];

  const handleBulkLive = async () => {
    try {
      const { data } = await api.post("/video/multiples", { data: multiples});
      navigate({ to: "/list" });
    } catch (error: any) {
      message.error("something went wrong");
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <Button onClick={handleBulkLive}>Live Via Studio</Button>,
    },
    {
      key: "2",
      label: <Button onClick={() => setOpen(true)}>Live Via Youtube</Button>,
    },
  ];

  async function handleYoutubeSchedule() {
    try {
      const payload =  { data: multiples, url: youtubeUrl }
      const { data } = await api.post("/live-youtube", payload);
      setOpen(true);
      navigate({ to: "/list" });
    } catch (error: any) {
      message.error("something went wrong");
    }
  }

  return (
    <>
      <Card
        title="Schedules Classes"
        extra={
          <Space>
            {multiples.length > 0 && (
              <Dropdown menu={{ items }} placement="bottomLeft">
                <Button>
                  <VideoCameraFilled />
                  Bulk Go Live
                </Button>
              </Dropdown>
            )}
            <Space style={{ margin: 20 }}>
              <Input
                onChange={({ target }) =>
                  setFilter((f) => ({ ...f, videoId: target.value }))
                }
                placeholder="Enter video id to Search"
              />
              <Select
                showSearch
                allowClear
                onClear={() => setFilter((f) => ({ ...f, course: 0 }))}
                fieldNames={{ label: "title", value: "id" }}
                placeholder="Select the course"
                onSearch={(value) => {}}
                options={filteredCourses}
                onSelect={(course) => setFilter((f) => ({ ...f, course }))}
                filterOption={(i, c) => (c?.title||'').toLowerCase()?.includes(i.toLowerCase()) }
              />
              <DatePicker
                onChange={(_, date) => setFilter((f) => ({ ...f, date }))}
                picker="date"
              />
            </Space>
            <Link to="/go-live" from="/">
              <Button danger={true}>
                <VideoCameraFilled />
                Go Live
              </Button>
            </Link>
          </Space>
        }
      >
        <Table columns={columns} dataSource={schedules} loading={loading} />
      </Card>
      <Modal
        open={open}
        title="Youtube Url"
        onOk={handleYoutubeSchedule}
        onCancel={() => setOpen(false)}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <Input
          placeholder="Enter Youtube link"
          onChange={({ target }) => setYoutubeUrl(target.value)}
        />
      </Modal>
    </>
  );
}
