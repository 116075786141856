import React, { useEffect, useState } from "react";
import { Badge, Card, Pagination, Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { EyeOutlined, YoutubeOutlined } from "@ant-design/icons";
import { Link } from "@tanstack/react-router";
import { useSocket } from "../utils/socket";
import VideoDetailDrawer from "../component/videoDetailDrawer";
import { IVideo, Status } from "../interfaces";
import HistoryFilter from "../component/history/HistoryFilter";
import LinkItem from "../component/history/LinkItem";
import { statusColor } from "../utils/constants";
import useList from "../hooks/useList";

export type scheduleType = "title" | "schedule_on" | "course_id";

export interface ICourse {
  id: string;
  title: string;
  lessons: string[];
}

function List({ type }: any) {
  // console.log({ type });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [viewableVideo, setViewableVideo] = useState<IVideo | null>(null);
  const { query, list, isLoading, updateList, updateQuery, onDelete } =
    useList(type);
  const socket = useSocket();
  const columns: ColumnsType<IVideo> = [
    {
      title: "S No.",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) => {
        return index + 1 + query.pageSize * (query.page - 1);
      },
      width: "7%",
      align: "center",
      responsive: ["lg", "md"],
    },
    {
      title: "Video Id",
      dataIndex: "vid",
      key: "vid",
      render: (vid, __, index) => vid,
      width: "10%",
      align: "center",
      responsive: ["lg", "md"],
    },
    {
      title: "Title",
      dataIndex: ["title", "url"],
      key: "title",
      render: (_, data) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={`/studio/$slug`}
          params={{ slug: data._id }}
        >
          {data.title}
        </Link>
      ),
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "schedule_at",
      key: "schedule_at",
      render: (schedule_at) => (
        <Badge>{moment(schedule_at).format("DD-MM-YY HH:MM")}</Badge>
      ),
      align: "center",
      responsive: ["lg"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: Status) => (
        <Tag color={statusColor[status]} key={status}>
          {status}
        </Tag>
      ),
      align: "center",
    },
    {
      title: "Video Link",
      dataIndex: "url",
      key: "url",
      render: LinkItem,
      responsive: ["lg"],
    },
    {
      title: "Views",
      dataIndex: "views",
      key: "views",
      render: (url, data) => {
        if (["yt", "ytst"].includes(data.videoType))
          return (
            <YoutubeOutlined
              style={{ color: "red", fontSize: 25 }}
              color="red"
            />
          );
        return (
          <EyeOutlined
            onClick={() => {
              setViewableVideo(data);
              setDrawerOpen(true);
            }}
          />
        );
      },
      align: "center",
    },
  ];

  const updateVideo = (video: IVideo) => {
    if (video._id === viewableVideo?._id) setViewableVideo(video);
    updateList(video);
  };

  useEffect(() => {
    socket.taskListUpdated(updateVideo);
    socket.liveRenderedList(updateVideo);
    socket.liveEndedList(updateVideo);
  }, [drawerOpen, updateVideo]);

  return (
    <>
      <Card
        type="inner"
        title="Logs & Activities"
        extra={
          <HistoryFilter
            query={query}
            setQuery={(query) => updateQuery({ ...query, page: 1 })}
          />
        }
      >
        <Table
          columns={columns}
          dataSource={list?.videos}
          pagination={false}
          loading={isLoading}
        />
        <Space>
          <Pagination
            hideOnSinglePage={true}
            style={{ margin: "10px 0" }}
            defaultCurrent={query.page}
            current={query.page}
            total={list?.count}
            onChange={(page, pageSize) => updateQuery({ page, pageSize })}
            defaultPageSize={10}
          />
        </Space>
      </Card>
      {viewableVideo && (
        <VideoDetailDrawer
          onDelete={(video) => onDelete(video)}
          video={viewableVideo!}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      )}
    </>
  );
}

export default List;
