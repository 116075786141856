import React, { RefObject } from "react";
import { formatDuration } from "../../utils";
import { Row } from "antd";
import { TTrimType } from "../../screens/editor";
// import Preview from "./preview";
// import RangeSlider from "./rangeSlider";
import MultiRangeSlider from "multi-range-slider-react";
// import Timeline from "react-visjs-timeline";

interface TrimmerProps {
  previewRefs: RefObject<HTMLCanvasElement>[];
  duration: number;
  value: [number, number];
  trimType: TTrimType;
  onChange: (value: [number, number]) => void;
}

const Trimmer = React.forwardRef<HTMLDivElement, TrimmerProps>(
  ({ previewRefs, duration, value, onChange, trimType, ...props }, ref) => {
    return (
      <Row
        ref={ref}
        {...props}
        style={{ width: "100vw", position: "relative" }}
      >
        {/* <Preview
          previewRefs={previewRefs}
          style={{
            // position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            flexWrap: "nowrap",
            alignItems: "stretch",
            // height: 40,
          }}
        /> */}
        <div style={{ width: "90vw" }}>
          <MultiRangeSlider
            min={0}
            max={Math.ceil(duration / 4) * 4}
            step={4}
            style={{ height: "100%" }}
            barLeftColor={trimType === "keep" ? "red" : "lime"}
            barRightColor={trimType === "keep" ? "red" : "lime"}
            barInnerColor={trimType === "keep" ? "lime" : "red"}
            minCaption={formatDuration(value[0])}
            maxCaption={formatDuration(value[1])}
            minValue={value[0]}
            maxValue={value[1]}
            onChange={(e) => onChange([e.minValue, e.maxValue])}
          />
        </div>
      </Row>
    );
  }
);

export default Trimmer;
