import { Card, Col, Image, Row } from "antd";
import { useParams } from "@tanstack/react-router";
import StudioLiveChat from "../component/Studio/StudioLiveChat";
import Engagement from "../utils/engagement";
// import { useSocket } from "../utils/socket";
import LiveStudio from "../component/Studio/liveStudio";
import YTVideo from "../component/Studio/YTVideo";
import Rendered from "../component/Studio/Rendered";
import useStudio from "../hooks/useStudio";
import { useState } from "react";

const AUTH_TOKEN = localStorage.getItem("AUTH_TOKEN");

export default function Studio() {
  const params = useParams({ from: "" });
  const { video, course, setVideo, iframeKey } = useStudio(params);
  const [player, setPlayer] = useState<"old" | "new">("old");

  if (video.videoType === "yt") return <YTVideo video={video} />;
  if (video.status === "rendered")
    return (
      <Rendered
        setPlayer={setPlayer}
        player={player}
        course={course}
        video={video}
      />
    );

  return (
    <Card loading={video ? false : true} className="bg-muted">
      <Row gutter={16}>
        <LiveStudio
          player={player}
          setPlayer={setPlayer}
          course={course!}
          frameKey={iframeKey}
          video={video}
          onDone={(video) => setVideo(video)}
        />
        {video.status === "schedule" ? (
          <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
            <Image src="/instruction.jpg" />
          </Col>
        ) : (
          <Col style={{}} xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
            {AUTH_TOKEN && (
              <StudioLiveChat
                videoId={video?.vid?.toString()}
                token={AUTH_TOKEN}
              />
            )}
            {video?.vid && <Engagement roomId={video?.vid} />}
          </Col>
        )}
      </Row>
    </Card>
  );
}
