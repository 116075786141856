import {
  Badge,
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Space,
  Tag,
  Tooltip,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import moment from "moment";
import { IVideo } from "../../interfaces";
import useClipboard from "../../utils";
import StudioLiveChat from "./StudioLiveChat";
import { statusColor } from "../../utils/constants";
import Engagement from "../../utils/engagement";
import ActionButton from "./ActionButton";

export default function YTVideo({ video }: { video: IVideo }) {
  const AUTH_TOKEN = localStorage.getItem("AUTH_TOKEN");
  const { copy } = useClipboard();
  return (
    <Card className="bg-muted">
      <Row gutter={30}>
        <Col span={12}>
          <Card
            title={`# ${video?.vid} Video Details`}
            type="inner"
            extra={
              <Space>
                <Tag color={statusColor[video.status]}>
                  <Badge
                    color={statusColor[video.status]}
                    text={video.status}
                  />
                </Tag>
                <ActionButton onDone={()=>console.log("done")} video={video} />
              </Space>
            }
          >
            <iframe
              title="KGS Player"
              height={420}
              width={"100%"}
              frameBorder={0}
              style={{
                borderRadius: 10,
                padding: 5,
              }}
              allow="autoplay"
              src={`https://khanglobalstudies.com/player?src=${video.url}&is_live=${video.status==='live'?1:0}`}
            ></iframe>
            <Descriptions layout="vertical" size="small" bordered={true}>
              <Descriptions.Item label="Title">{video.title}</Descriptions.Item>
              <Descriptions.Item label="Scheduled At" span={3}>
                {moment(video.schedule_at).format("DD-MM-YY HH:SS")}
              </Descriptions.Item>
              <Descriptions.Item label="Video Url" span={3}>
                {video.url}
                <Tooltip title="Click to copy">
                  <Button
                    onClick={() => copy(video.url, "Video Url copied")}
                    type="dashed"
                    style={{ marginLeft: 5 }}
                    icon={<CopyOutlined />}
                  />
                </Tooltip>
              </Descriptions.Item>
            </Descriptions>
            {video?.vid && video.status==='live'&& <Engagement roomId={video?.vid} />}
          </Card>
        </Col>
        <Col span={12}>
          <StudioLiveChat
            videoId={video?.vid?.toString()}
            token={AUTH_TOKEN!}
          />
        </Col>
      </Row>
    </Card>
  );
}
