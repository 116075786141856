import {
  Card,
  Tabs,
  TabsProps
} from "antd";

import StudioSettingsCard from "../component/Cards/StudioSettingsCard";
import BucketDetailCard from "../component/Cards/BucketDetailCard";


export default function Config() {
 
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Cloud Bucket Configurations',
      children: <BucketDetailCard />,
    },
    {
      key: '2',
      label: 'Studio Settings',
      children: <StudioSettingsCard />
    },
  ];

  return (
    <div>
      <Card title="Studio Configuration" type="inner">
        <Tabs defaultActiveKey="1" items={items} />
      </Card>
    </div>
  );
}

