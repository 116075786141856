import { useEffect, useState } from "react";
import { IVideo } from "../interfaces";
import { useSocket } from "../utils/socket";
import useCourseStore from "../store/course";
import api from "../api";
import { ICourse } from "../screens/list";

export default function useStudio(params) {
  const [latest, setLatest] = useState<IVideo>({ title: "" } as IVideo);
  const [course, setCourse] = useState<ICourse>();
  const [iframeKey, setIframeKey] = useState(0);
  const socket = useSocket();
  const { getCourse } = useCourseStore();

  const getLatest = async () => {
    try {
      const { data } = await api.get<IVideo>(
        `video/${params?.slug || "getlatest"}`
      );
      setLatest(data);
      setCourse(getCourse(data.course_id));
    } catch (error: any) {}
  };

  const registerSocket = () => {
    if (!latest._id) return;
    socket.liveEnded(latest._id, latest.stream_key, (video) => {
      setLatest(video);
    });
    socket.streamingStarted(latest._id, latest.stream_key, (video) => {
      setTimeout(() => {
        setIframeKey((key) => key + 1);
      }, 1000);
    });
    socket.taskUpdated(latest._id, latest.stream_key, (video) =>
      setLatest(video)
    );
    socket.liveRendered(latest._id, latest.stream_key, (video) =>
      setLatest(video)
    );
  };

  useEffect(() => {
    getLatest();
    registerSocket();
  }, [latest._id]);

  return { video: latest,course ,setVideo:setLatest,iframeKey};
}
