import {
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  Descriptions,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  message,
} from "antd";
import { IVideo } from "../../interfaces";
import StudioPlayer from "./StudioPlayer";
import { IURL } from "./VideoDetail";
import { useEffect, useState } from "react";
import { ICourse } from "../../screens/list";
import { CopyOutlined } from "@ant-design/icons";
import useClipboard from "../../utils";
import useBucketStore from "../../store";
import moment from "moment";
import { Link } from "@tanstack/react-router";

export default function Rendered({
  video,
  course,
  player,
  setPlayer
}: {
  video: IVideo;
  course?: ICourse;
  player:'old'|"new";
  setPlayer:(player:'old'|"new")=>void
}) {
  const { buckets } = useBucketStore();
  // const cdnURL = urlToCDN(
  //   video.url + "/" + video.stream_key + ".m3u8",
  //   buckets
  // );
  const cdnURL = video.cdnUrl.includes('.m3u8')?video.cdnUrl:`${video.cdnUrl}/${video.stream_key}.m3u8`
  const urls = [
    {
      label: "Recorded Video",
      value: cdnURL,
    },
  ];
  const [playerUrls, setPlayerUrls] = useState<IURL[]>(urls);
  const [activeUrl, setActiveUrl] = useState(playerUrls[0]);
  const { copy } = useClipboard();

  useEffect(() => {
    if (!video._id) return;
    const urls = [
      {
        label: "Recorded Video",
        value: cdnURL,
      },
    ];
    if (video.backupUrl) {
      urls.push({ value: video.backupUrl, label: "Backup video" });
    }
    if (video.downloadUrl) {
      urls.push({ value: video.downloadUrl, label: "Download video" });
    }
    setPlayerUrls(urls);
    setActiveUrl(urls[0]);
  }, [video]);

  const handleChangeUrl = (_: any, option: any) => {
    setActiveUrl(option);
  };

  const renderUrl = [
    {
      label: "Backup URL",
      url: video.backupUrl,
      message: "BackupUrl Copied",
    },
    {
      label: "Download URL",
      url: video.downloadUrl,
      message: "Download Url Copied",
    },
    {
      label: "Recorded URL",
      url: video.url + "/" + video.stream_key + ".m3u8",
      message: "Recorded URL Copied",
    },
    {
      label: "Recorded Cdn Url",
      url: cdnURL,
      message: "Cdn Url Copied",
    },
  ];
  const items = renderUrl.map((item, index) => ({
    label: item.label,
    children: (
      <>
        {item.url}
        <Tooltip title="Click to copy">
          <Button
            onClick={() => copy(item.url, item.message)}
            type="dashed"
            style={{ marginLeft: 5 }}
            icon={<CopyOutlined />}
          />
        </Tooltip>
      </>
    ),
  }));

  return (
    <Card loading={video.url ? false : true} className="bg-muted">
      <Row gutter={16}>
        <Col span={24} style={{ display: "flex" }}>
          <Col span={12}>
            <Card
              title={`# ${video?.vid} Video Details`}
              type="inner"
              extra={
                <Space>
                  <Tag color="blue">
                    <Badge color="blue" text={video.status} />
                  </Tag>
                </Space>
              }
            >
              {video.stream_key !== undefined && (
                <StudioPlayer player={player} videoUrl={activeUrl?.value} />
              )}
              <Descriptions layout="vertical" size="small" bordered={true}>
              <Descriptions.Item label="Change Player" span={4}>
                  <Select
                    defaultValue={player}
                    showSearch
                    style={{ width: "100%" }}
                    onSelect={setPlayer}
                    options={[
                      {
                        label:"New Player",
                        value:"new"
                      },
                      {
                        label:"Old Player",
                        value:"old"
                      }
                    ]}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Change Video" span={4}>
                  <Select
                    defaultValue={activeUrl?.value}
                    showSearch
                    style={{ width: "100%" }}
                    onSelect={handleChangeUrl}
                    options={playerUrls}
                  />
                </Descriptions.Item>
                {/* <Descriptions.Item label="Edit Video" span={3}>
                  <Link
                    target="blank"
                    to="/editor"
                    search={{
                      vid: video._id,
                      url: cdnURL,
                    }}
                  >
                    Edit
                  </Link>
                </Descriptions.Item> */}
                <Descriptions.Item label="Title">
                  {video.title}
                </Descriptions.Item>
                <Descriptions.Item label="Scheduled At" span={3}>
                  {moment(video?.schedule_at).format("DD-MM-YY HH:SS")}
                </Descriptions.Item>
                <Descriptions.Item label="Course" span={3}>
                  {course?.title}
                </Descriptions.Item>
                <Descriptions.Item label="Selected Bucket" span={3}>
                  <Select
                    defaultValue={video.bucket}
                    showSearch
                    disabled={true}
                    placeholder="Select where to store"
                    style={{ width: "100%" }}
                    onSelect={(e) =>
                      message.error(
                        "U Can't change Bucket as classes are rendered"
                      )
                    }
                    options={buckets?.map((val) => ({
                      value: val?._id,
                      label: val?.title,
                    }))}
                  />
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Collapse
                items={items}
                defaultActiveKey={[`${items.length - 1}`]}
              />
            </Card>
          </Col>
        </Col>
      </Row>
    </Card>
  );
}
