export default function Loader({ isLoading }: any) {
  if (!isLoading) return <div/>;
  return (
    <div className="loader" style={{position:'absolute', width:'90%', height:'100%'}}>
      <div className="cent">
        <div className="loading-indicator">
          <div></div>
          <div></div>
        </div>
      </div>
      <h2 className="text-center ">Please wait while your video is trimming...</h2>
    </div>
  );
}
