import { useEffect } from "react";
import socketIO from "./utils/socket";
import { ConfigProvider } from "antd";
import { RouterProvider } from "@tanstack/react-router";
import router from "./routes";
import useSocketStore from "./store/socket";
import { useOnline } from "./utils";
export default function App() {
  const { setSocket } = useSocketStore();
 
  useEffect(() => {
    const socket = new socketIO();
    setSocket(socket);
  }, []);
  
  const offline = useOnline();
    return (
    <>
        <ConfigProvider
        // theme={{
        //   components: {
            
        //   },
        // }}
          theme={{
            token: {
              colorPrimary: "#00b96b",
              colorLink: "#5458C9",
            },
            components: {
              Segmented: {
                itemSelectedBg: "#00b96b",
                colorBgBase: "#5458C9",
                itemHoverColor: "white",
                itemColor: "white",
              },
            },
          }}
        >

          <RouterProvider router={router} />
        </ConfigProvider>
      {!offline && <p className="offline"> Offline, please check your connection</p>}
      {offline && <p className="online"> You're back to Online</p>}
      
    </>
  );
}
