import { Button, Card, Col, Form, Input, Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import api, {  LOGIN_URL, loginRequest } from '../../api';
import { useState } from 'react';
import { IConfig } from '../../interfaces';
import useBucketStore from '../../store';
import { useNavigate, useSearch } from '@tanstack/react-router';

const { Title } = Typography;

export default function Login() {
	const [form] = useForm();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const navigate = useNavigate({ from: '/login' });
	const { redirect } = useSearch({ from: '/login' });
	const { createBucket, setError } = useBucketStore();

	const handleLogin = async (values: any) => {
		try {
			setIsSubmitting(true);
			const { data } = await api.post(LOGIN_URL, values,{headers:{
			}});
			if (data.token) {
				localStorage.setItem('AUTH_TOKEN', data.token);
				loginRequest();
				localStorage.setItem(
					'LOGGED_IN_USER',
					JSON.stringify(data.user)
				);
				await getSettings();
				await fetchConfig();
				navigate({ to: redirect || '/' });
				return;
			}
			setIsSubmitting(false);
		} catch (e: any) {
			setIsSubmitting(false);
		}
	};
	const fetchConfig = async () => {
		try {
			const req = await api.get<IConfig[]>('/cred');
			createBucket(req.data);
		} catch (e: any) {
			setError(e.message);
		}
	};
	const getSettings = async () => {
		try {
			const { data } = await api.get<any[]>('/settings');
			data.forEach((bucket) => {
				localStorage.setItem(bucket.name, bucket.value);
			});
		} catch (e) {
			console.log('error while setting bucket', e);
		}
	};

	return (
		<div style={{ height: '100vh', overflow: 'hidden' }}>
			<Row
				className="bg-muted"
				align="middle"
				justify="center"
				style={{ height: '100%' }}
			>
				<Col
					xs={20}
					sm={16}
					md={12}
					lg={10}
					xl={8}
				>
					<Card style={{ backgroundColor: 'white' }}>
						<Typography>
							<img
								src="kgs-studio.png"
								width="250px"
								height="auto"
								alt="kgs studio"
							/>
							<Title
								style={{ margin: '10px 0' }}
								level={3}
							>
								Login to Your Account
							</Title>
						</Typography>
						<Form
							form={form}
							layout="vertical"
							onFinish={handleLogin}
							size="large"
							onFinishFailed={() => {}}
						>
							<Form.Item
								label="Your Email"
								name="email"
								rules={[
									{
										required: true,
										message: 'Please enter email address',
									},
								]}
							>
								<Input type="email" />
							</Form.Item>
							<Form.Item
								label="Password"
								name="password"
								rules={[
									{
										required: true,
										message: 'please enter your password',
									},
								]}
							>
								<Input type="password" />
							</Form.Item>

							<Form.Item>
								<Button
									type="primary"
									htmlType="submit"
									size="large"
									loading={isSubmitting}
									style={{
										display: 'flex',
										flexDirection: 'row-reverse',
										alignItems: 'center',
										gap: '8px',
									}}
								>
									Login to KGS Studio
								</Button>
							</Form.Item>
						</Form>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
