import { useEffect, useState } from "react";
import { IVideo } from "../interfaces";
import useApi from "./useApi";
import { IQuery } from "../component/history/HistoryFilter";

interface IList {
  videos?: IVideo[];
  count?: number;
}

export default function useList(videoType: "yt" | "") {
  const [query, setQuery] = useState<IQuery>({
    title: "",
    vid: undefined,
    status: "",
    courseId: "",
    date: "",
    page: 1,
    pageSize: 10,
    videoType,
  });

  const { data, isLoading, load, updateData } = useApi<IList>("/video", []);

  const onDelete = (video: IVideo) => {
    const update = {
      videos: data?.videos?.filter((v) => v._id !== video._id),
      count: data?.count,
    };
    updateData(update);
  };

  const updateList = (video: IVideo) => {
    const update = {
      videos: data?.videos?.map((v) =>
        v._id === video?._id ? { ...v, ...video } : v
      ),
      count: data?.count,
    };
    updateData(update);
  };
  const updateQuery = (newQuery: any) => {
    setQuery((query) => ({ ...query, ...newQuery }));
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      load(query);
    }, 500);
    return () => clearTimeout(getData);
  }, [query, load]);

  return {
    list: data,
    query,
    updateList,
    isLoading,
    updateQuery,
    onDelete,
    setQuery,
  };
}
