import { useResizeObserver } from "@mantine/hooks";
import { Row } from "antd";
import { useEffect, useRef, useState } from "react";
import ReactHlsPlayer from "react-hls-player";
import { useTimeline } from "../../hooks/useTimeline";
import Trimmer from "./trimmer";
import { TTrimType } from "../../screens/editor";
import Loader from "./loader";

interface HlsVideoTrimmerProps {
  hlsLiveStreamUrl: string;
  rangeValue:[number,number]
  setRangeValue:React.Dispatch<React.SetStateAction<[number, number]>>
  trimType:TTrimType
  loading:boolean
}

const HlsVideoTrimmer = ({ hlsLiveStreamUrl ,rangeValue, setRangeValue, trimType,loading }: HlsVideoTrimmerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [duration, setDuration] = useState(0);
  const [trimmerRef, trimmerRect] = useResizeObserver();

  const { thumbnailRef, previewRefs } = useTimeline({
    sliderWidth: trimmerRect.width,
  });

  const [start, end] = rangeValue;

  useEffect(() => {
    videoRef.current && (videoRef.current.currentTime = start);
  }, [start]);

  useEffect(() => {
    videoRef.current && (videoRef.current.currentTime = end);
  }, [end]);

  useEffect(() => {
    if (!videoRef.current) return;
    videoRef.current.onloadedmetadata = () => {
      setDuration(videoRef.current?.duration || 0);
      setRangeValue([0, videoRef.current?.duration || 0]);
    };
  }, [videoRef, setDuration]);

  return (
    <Row>
      <Row style={{position:"relative",maxWidth:'100%',width:'100%',alignItems:'center',alignContent:'center',justifyContent:'center'}}>
      <ReactHlsPlayer
        src={hlsLiveStreamUrl}
        autoPlay={false}
        controls={false}
        width="100%"
        style={{
          maxHeight: "70vh",
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -10,
        }}
        playerRef={thumbnailRef}
      />

      <ReactHlsPlayer
        src={hlsLiveStreamUrl}
        autoPlay={true}
        controls
        width="90%"
        style={{ }}
        playerRef={videoRef}
      />
       <Loader isLoading={loading}  />
      </Row>

      <Trimmer
        trimType={trimType}
        duration={duration}
        value={rangeValue}
        onChange={setRangeValue}
        previewRefs={previewRefs}
        ref={trimmerRef}
      />
    </Row>
  );
};

export default HlsVideoTrimmer;
