import {
  Col,
  Form,
  Row,
  Select,
  Card,
  message,
  Input,
  Space,
  Button,
} from "antd";
import api from "../../api";
import { useState } from "react";
import { IConfig } from "../../interfaces";
import useBucketStore from "../../store";


export default function StudioSettingsCard() {
  const {buckets} = useBucketStore()
  const [form] = Form.useForm();
  const [defaultBucketForm] = Form.useForm();
  const [cdn, setcdn] = useState("");
  const live_CDN = localStorage.getItem('live_CDN')
  console.log({live_CDN})

  const handleSubmit = async (name: string, value: string) => {
    if (value == "") return message.success(`please enter value`);
    try {
      console.log({ name, value })
      const res = await api.post("/settings", { name, value });
      console.log({res})
      localStorage.setItem(name, value);
      message.success(`setting updated`);
    } catch (e) {
      message.error(`error while updating bucket`);
    }
  };
  
  return (
    <Row gutter={10}>
      <Col xs={24} sm={24} md={24} lg={24} xl={12} >
        <Card title="Backup & Download" type="inner">
          <Form form={form} layout="vertical">
            <Form.Item
              label="720p MP4 Backup Bucket"
              name="backup"
              rules={[
                {
                  required: true,
                  message: "Select Backup is required",
                },
              ]}
            >
              <Select
                showSearch
                defaultValue={localStorage.getItem("720_bucket")}
                onSelect={(e) => handleSubmit("720_bucket", e)}
                style={{ width: "100%" }}
                options={buckets.map((val: any) => ({
                  value: val._id,
                  label: val.title,
                }))}
              />
            </Form.Item>
            <Form.Item
              label="360p MP4 Download Bucket"
              name="download"
              rules={[
                {
                  required: true,
                  message: "download is required",
                },
              ]}
            >
              <Select
                defaultValue={localStorage.getItem("360_bucket")}
                showSearch
                placeholder="Select where to store"
                style={{ width: "100%" }}
                onSelect={(e) => handleSubmit("360_bucket", e)}
                options={buckets.map((val) => ({
                  value: val._id,
                  label: val.title,
                }))}
              />
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={12} span={12}>
        <Card title="Default Bucket" type="inner">
          <Form form={defaultBucketForm} layout="vertical">
            <Form.Item
              label="Recorded video bucket"
              name="backup"
              rules={[
                {
                  required: true,
                  message: "Select Backup is required",
                },
              ]}
            >
              <Select
                showSearch
                defaultValue={localStorage.getItem('default_bucket')}
                onSelect={(e) => handleSubmit("default_bucket", e)}
                style={{ width: "100%" }}
                options={buckets.map((val: any) => ({
                  value: val._id,
                  label: val.title,
                }))}
              />
            </Form.Item>
            <Form.Item
              label="CDN Url for live stream"
              name="cdn"
              rules={[
                {
                  required: true,
                  message: "cdn url is required for live stream",
                },
              ]}
            >
              <Space.Compact style={{ width: "100%" }}>
                <Input
                  placeholder="Enter valid cdn url"
                  onChange={(event) => setcdn(event.target.value)}
                  defaultValue={live_CDN||''}
                />
                <Button
                  loading={false}
                  onClick={() =>
                    handleSubmit("live_CDN", cdn?.replace(/\/$/, ""))
                  }
                  type="primary"
                >
                  Submit
                </Button>
              </Space.Compact>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
