import { Button, Timeline } from "antd";
import { useEffect, useState } from "react";
import { IVideo } from "../interfaces";
import api from "../api";
import { LoadingOutlined } from "@ant-design/icons";

const STATUS_COLOR = {
  success: "green",
  failed: "red",
  active: "blue",
  pending: "gray",
};

const MESSAGES = {
  rendring: {
    active: "Hls video uploading 🚧",
    pending: "Hls video ⏳",
    failed: "Failed to upload hls video ❌",
    success: "Hls video uploaded successfully ✅ ",
  },
  backupConversion: {
    failed: "Failed to convert 720 video ❌",
    active: "720p video converting 🚧",
    pending: "720p video conversation ⏳",
    success: "720p video converted successfully ✅ ",
  },

  downloadConversion: {
    failed: "Failed to convert 360 video ❌",
    active: "360p video converting 🚧",
    pending: "360p video conversation ⏳",
    success: "360p video converted successfully  ✅",
  },
  backupRendring: {
    failed: "Failed to upload 720 video ❌",
    active: "720p video uploading 🚧",
    pending: "720p video upload ⏳",
    success: "720p video uploaded successfully  ✅",
  },
  downloadRendring: {
    failed: "Failed to upload 360 video ❌",
    active: "360p video uploading 🚧",
    pending: "360p video upload ⏳",
    success: "360p video uploaded successfully ✅",
  },
};

function VideoProgress({ video }: { video: IVideo }) {
  console.log({ video });
  const [timelines, setTimelines] = useState<any>(null);

  const handleResumeVideo = async (taskType: IVideo["tasks"][0]["type"]) => {
    await api.post(`/resume/${video._id}`, {
      taskType,
    });
  };
  useEffect(() => {
    setTimelines(
      video.tasks.map((task) => ({
        dot: task.status === "active" && <LoadingOutlined />,
        color: STATUS_COLOR[task.status],
        children: (
          <>
            {MESSAGES[task.type][task.status]}
            {task.status === "failed" && (
              <Button
                onClick={() => handleResumeVideo(task.type)}
                title="Try Again"
              >
                Try Again
              </Button>
            )}
          </>
        ),
      }))
    );
  }, [video.tasks]);

  if (!["rendering", "rendered"].includes(video.status))
    return (
      <div style={{ marginTop: 10, fontSize: 16, color: "gray" }}>
        Rendering not started yet 😌{" "}
      </div>
    );
  return <Timeline style={{ marginTop: 30 }} items={timelines} />;
}

export default VideoProgress;
