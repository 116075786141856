import { Button, Modal, message } from "antd";
import { IVideo } from "../../interfaces";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useState } from "react";
import api from "../../api";

interface IProps {
  video: IVideo;
  onDone: (video: IVideo) => void;
}

export default function ActionButton({ video, onDone }: IProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = async () => {
    setIsLoading(true);
    let url = "";
    if (video.status === "schedule") {
      url = "go-live";
    }
    if (["ended", "live"].includes(video.status)) {
      url = "end-live";
    }

    if (video.videoType === "ytst") {
      url = "end-youtube";
    }
    try {
      const { data } = await api.get<IVideo>(url, {
        params: {
          videoId: video._id,
          stream_key: video.stream_key,
        },
      });
      onDone(data);
      setIsLoading(false);
      return;
    } catch (error: any) {
      console.log({ error });
      message.error(error?.response?.data?.error || "Server Error");
      setIsLoading(false);
    }
  };
  const showConfirmation = () => {
    Modal.confirm({
      title: "Are you sure you want to do this?",
      icon: <ExclamationCircleFilled />,
      content: "",
      onOk() {
        handleUpdate();
      },
      onCancel() {},
    });
  };

  if (video.status === "schedule")
    return (
      <Button onClick={showConfirmation} type="primary" loading={isLoading}>
        Start streaming
      </Button>
    );

  if (video.status === "live") {
    return (
      <Button onClick={showConfirmation} type="primary" loading={isLoading}>
        End Live
      </Button>
    );
  }
  return <div />;
}
