import React, { useEffect } from "react";
import { Layout, message } from "antd";
import "./App.css";
import styled from "styled-components";
import useCourseStore from "../store/course";
import useBucketStore from "../store";
import api, { COURSES_URL, loginRequest } from "../api";
import { ICourse } from "../screens/list";
import SideBar from "./sideBar";
import { IConfig, ISetting } from "../interfaces";

const { Content } = Layout;

const isAuthenticated = ()=>{
  const token = localStorage.getItem("AUTH_TOKEN");
  return token
}

function DashboardLayout({
  showSideBar = true,
  children,
  defalutSelectedKey,
}: any) {
  const { setCourses } = useCourseStore();
  const { createBucket, setError } = useBucketStore();
  const token = localStorage.getItem("AUTH_TOKEN");
  const location = window.location.pathname

  const fetchConfig = async () => {
    try {
      const { data } = await api.get<IConfig[]>("/cred");
      createBucket(data)
    } catch (e: any) {
      message.error(e.message)
    }
  };

  const getCourses = async () => {
    try {
      const { data } = await api.get<ICourse[]>(COURSES_URL);
      setCourses(data);
    } catch (e: any) {
      setError(e.message);
    }
  };
  const getSettings = async () => {
    try {
      const { data } = await api.get<ISetting[]>("/settings");
      data.forEach((bucket) => {
				// localStorage.setItem(bucket.name, bucket.value);
			});
    } catch (e: any) {
		  message.error(e.message)
      console.log({ e });
    }
  };


  useEffect(() => {
    getCourses();
    loginRequest();
    if (token && location !== '/login') {
      getSettings();
      fetchConfig();
    }
  }, []);

  return (
    <Layout className="layout-container">
      {isAuthenticated() && <SideBar defaultSelectedKeys={defalutSelectedKey} />}
      <Layout>
        <ContentContainer>{children}</ContentContainer>
      </Layout>
    </Layout>
  );
}

// const LogoContainer = styled.div`
//   font-size: 25px;
//   display: flex;
//   align-items: flex-end;
//   padding: 10px;
//   margin: 5px;
//   border-radius: 10px;
//   color: white;
// `;

const ContentContainer = styled(Content)`
  background: white;
  margin: 10px;
  border-radius: 10px;
  background: white;
  box-shadow: -13px -13px 26px #dedede, 13px 13px 26px #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export default DashboardLayout;
